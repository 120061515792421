var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-actions",
        {
          attrs: {
            "show-loading": _vm.saving || _vm.fetching,
            "action-collapse": "",
            "action-refresh": "",
          },
          on: { refresh: _vm.getData },
        },
        [
          _c(
            "e-filters",
            {
              staticClass: "mb-2 card-bordered",
              attrs: {
                "search-text": _vm.$t("Filtrar"),
                searching: _vm.fetching,
                title: _vm.$t("Filtrar Antecipações"),
              },
              on: { search: _vm.getData, reset: _vm.resetFiltersLocal },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "antecipation_filter-value",
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                          label: _vm.$t("Valor Líquido"),
                          placeholder: _vm.$t("Valor"),
                        },
                        model: {
                          value: _vm.antecipationFilters.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.antecipationFilters, "value", $$v)
                          },
                          expression: "antecipationFilters.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "antecipation_filter-acquirer_product_id",
                          type: "vue-select",
                          options: _vm.getnetAcquirerProductsOptions,
                          label: _vm.$t("Forma de pagamento Getnet"),
                          placeholder: _vm.$t("Selecione"),
                        },
                        model: {
                          value: _vm.antecipationFilters.acquirerProductId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.antecipationFilters,
                              "acquirerProductId",
                              $$v
                            )
                          },
                          expression: "antecipationFilters.acquirerProductId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            ref: "antecipation-items-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("ANTECIPATION_ITEM.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.antecipationItems,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(saleSummaryDate)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("date")(row.item.saleSummaryDate, "-")) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(saleSummaryCreditDate)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("date")(row.item.saleSummaryCreditDate, "-")
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(references)",
                fn: function (row) {
                  return [
                    row.item.accountPayableId
                      ? _c("e-router-link", {
                          attrs: {
                            title: _vm.$t("Visualizar despesa"),
                            to: {
                              name: "expense-maintain",
                              params: { id: row.item.accountPayableId },
                            },
                            value: row.item.accountPayableId,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "custom-foot",
                fn: function () {
                  return [
                    _c("tr", [
                      _c("th", { attrs: { colspan: "3" } }),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.getnetTotalGrossValue)
                            ) +
                            " "
                        ),
                      ]),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.getnetTotalNetValue)
                            ) +
                            " "
                        ),
                      ]),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.erpTotalTaxDiscountValue)
                            ) +
                            " "
                        ),
                      ]),
                      _c("th", { attrs: { colspan: "3" } }),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }