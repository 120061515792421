var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-skip-item",
        title: _vm.titleModal,
        "no-close-on-backdrop": "",
        scrollable: "",
        centered: "",
        size: "md",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-row",
                {
                  staticClass:
                    "w-100 d-flex justify-content-between align-items-center",
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          id: "modal-skip-item-items-btn_cancel",
                          variant: "outline-primary",
                          text: _vm.$t("Cancelar"),
                        },
                        on: { click: _vm.hideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          id: "modal-skip-item-items-btn_confirm",
                          variant: "primary",
                          text: _vm.$t("Confirmar"),
                          busy: _vm.saving,
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localShowModal,
        callback: function ($$v) {
          _vm.localShowModal = $$v
        },
        expression: "localShowModal",
      },
    },
    [
      _c(
        "FormulateForm",
        { ref: "form", on: { submit: _vm.onConfirm } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "skip-item-input-nsu",
                      value: _vm.transactionTime,
                      label: _vm.$t("Horário"),
                      type: "label",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "skip-item-input-value",
                      value: _vm.transactionValue / 100,
                      label: _vm.$t("Valor"),
                      type: "label",
                      filter: "currency",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "skip-item-comments",
                      label: _vm.$t("Observação"),
                      type: "textarea",
                      rows: "3",
                    },
                    model: {
                      value: _vm.localData.comments,
                      callback: function ($$v) {
                        _vm.$set(_vm.localData, "comments", $$v)
                      },
                      expression: "localData.comments",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }