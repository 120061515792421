<template>
  <section>
    <b-card-actions
      v-if="!isEdit"
      :show-loading="uploading || fetching"
      action-collapse
      :title="$t('Importar arquivo de conciliação')"
    >
      <FormulateForm
        v-show="!uploading"
        ref="form"
      >
        <b-row>
          <b-col md="12">
            <FormulateInput
              id="reconciliation-file"
              ref="getnetFile"
              v-model="localReconciliationFile"
              type="uploader"
              max-files="1"
              class="required"
              :theme="'list'"
              :deletable="!isEdit"
              :meta="true"
              :accept="'.txt'"
              :label="$t('Arquivo Getnet')"
              validation="required"
              :help-text="
                $t(
                  'Arraste o arquivo de conciliação da Getnet aqui (ou clique para selecionar o arquivo)'
                )
              "
              @input="uploadFile"
              @delete="removeFiles"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </b-card-actions>

    <transition
      v-else
      name="fade"
    >
      <b-row>
        <b-col md="12">
          <b-card-actions
            :show-loading="saving"
            action-collapse
            :title="`${$t('Dados da Conciliação')} #${reconciliation.id}`"
            class="h-345"
          >
            <b-row>
              <b-col md="3">
                <FormulateInput
                  id="reconciliation-store"
                  :value="reconciliation.checkingAccount.store"
                  type="label"
                  filter="storeName"
                  :label="$t('Loja')"
                />
              </b-col>
              <b-col md="3">
                <FormulateInput
                  id="reconciliation-checking-account"
                  :value="reconciliation.checkingAccount.name"
                  type="label"
                  :label="$t('Conta Corrente')"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="reconciliation-movement-date"
                  :value="reconciliation.movementDate"
                  type="label"
                  filter="date"
                  :label="$t('Data do Movimento')"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="invoice-status"
                  :value="$t(`Reconciliation_${reconciliation.status}`)"
                  type="label"
                  :label="$t('Status')"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="invoice-percent-completed"
                  :value="reconciliation.percentCompleted / 100"
                  type="label"
                  filter="percentage"
                  :label="$t('% Concluído')"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="invoice-percent-comission-credit-tax"
                  :value="(reconciliation.percentComissionCreditTax || 0) / 100"
                  type="label"
                  filter="percentage"
                  :label="$t('Taxa Crédito')"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="invoice-percent-comission-debit-tax"
                  :value="(reconciliation.percentComissionDebitTax || 0) / 100"
                  type="label"
                  filter="percentage"
                  :label="$t('Taxa Débito')"
                />
              </b-col>
              <b-col md="2">
                <FormulateInput
                  id="invoice-percent-antecipation-tax"
                  :value="(reconciliation.percentAntecipationComissionTax || 0) / 100"
                  type="label"
                  filter="percentage"
                  :label="$t('% Taxa Antecipação')"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>

        <b-col md="12">
          <b-tabs fill>
            <b-tab :title="$t('Transações')">
              <reconciliation-item-table
                :saving="saving"
                :fetching="fetching"
                @get-data="getData"
                @item-skip="onSkip"
                @item-match="onMatch"
                @item-unmatch="onUnmatch"
                @item-reset="onReset"
              />
            </b-tab>

            <b-tab :title="$t('Antecipações')">
              <reconciliation-antecipation-item-table
                :saving="saving"
                :fetching="fetchingAntecipation"
                @get-data="getAntecipationData"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </transition>

    <skip-item
      ref="skipModal"
      :reconciliation-id="reconciliation.id"
    />
    <match-item
      ref="matchModal"
      :reconciliation="reconciliation"
    />

    <fab
      v-if="$can('Update', 'GetnetReconciliation') && !isFinished"
      :main-tooltip="$t('Finalizar Conciliação')"
      main-icon="check"
      :busy="saving"
      @click="onFinishReconciliation"
    />
  </section>
</template>

<script>
import { BRow, BCol, BTabs, BTab } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapState } from 'vuex'
import { uploader, reconciliationDomains } from '@/mixins'
import fab from '@/views/components/FAB.vue'
import SkipItem from './components/SkipItem.vue'
import MatchItem from './components/MatchItem.vue'
import ReconciliationItemTable from './components/ReconciliationItemTable.vue'
import ReconciliationAntecipationItemTable from './components/ReconciliationAntecipationItemTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardActions,
    SkipItem,
    MatchItem,
    fab,
    ReconciliationItemTable,
    ReconciliationAntecipationItemTable,
  },

  mixins: [uploader, reconciliationDomains],

  data() {
    return {
      mode: 'xml',
      // store: null,
      // reconciliationFile: [],
      uploading: false,
      saving: false,
      fetching: false,
      fetchingAntecipation: false,
    }
  },

  computed: {
    ...mapState('pages/financial/reconciliation/getnet/maintain', [
      'reconciliation',
      'reconciliationFile',
    ]),

    reconciliationId() {
      return this.$route.params?.id
    },
    readOnly() {
      return this.reconciliation?.status === 'Completed'
    },
    localReconciliationFile: {
      get() {
        return this.reconciliationFile
      },
      set(val) {
        this.setReconciliationFile(val)
      },
    },
    hasFile() {
      return this.reconciliationFile.length > 0
    },
    isEdit() {
      return !!this.reconciliation?.id
    },
    storeLabel() {
      return this.reconciliation?.store?.name
    },
    fileName() {
      return this.reconciliationFile?.name
    },
    isFinished() {
      return this.reconciliation?.status === this.statusReconciliationEnum.Finished
    },
  },

  created() {
    if (this.reconciliationId) this.cleanState()
  },

  async mounted() {
    if (this.reconciliationId) {
      try {
        this.fetching = true
        await this.fetchReconciliation(this.reconciliationId)
        await this.fetchReconciliationItems(this.reconciliationId)
        await this.fetchAntecipationItems(this.reconciliationId)
      } catch (error) {
        this.showError({
          error,
          message: this.$t('Não foi possível obter os dados do Pedido de Compra'),
        })
        this.$router.back()
      } finally {
        this.fetching = false
      }
    }
  },

  methods: {
    ...mapActions('pages/financial/reconciliation/getnet', ['fetchManagementAccounts']),
    ...mapActions('pages/financial/reconciliation/getnet/maintain', [
      'fetchReconciliation',
      'finishReconciliation',
      'fetchReconciliationItems',
      'fetchAntecipationItems',
      'setReconciliationFile',
      'sendReconciliationFile',
      'matchItem',
      'unmatchItem',
      'skipItem',
      'resetItem',
      'cleanState',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchReconciliationItems(this.reconciliationId)
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },
    async getAntecipationData() {
      this.$nextTick(async () => {
        try {
          this.fetchingAntecipation = true
          await this.fetchAntecipationItems(this.reconciliationId)
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetchingAntecipation = false
        }
      })
    },

    async uploadFile() {
      if (this.isEdit || !this.reconciliationFile?.length) return
      this.$refs.form.showErrors()
      try {
        this.uploading = true
        const reconciliationId = await this.sendReconciliationFile({
          file: this.reconciliationFile[0],
        })
        this.$router.push({
          name: 'reconciliation-getnet-maintain',
          params: { id: reconciliationId },
        })
      } catch (error) {
        this.showError({
          error,
          message: this.$t('Não foi possível fazer a leitura do arquivo'),
        })
        this.cleanState()
      } finally {
        this.uploading = false
      }
    },

    async removeFiles() {
      this.cleanState()
      this.reconciliationFile = []
    },

    async onFinishReconciliation() {
      try {
        if (
          await this.confirm({
            text: this.$t(
              'Confirma a finalização desta conciliação? Os recebíveis associados terão seus pagamentos registrados e a conta corrente será atualizada. Não é possível desfazer esta ação.'
            ),
          })
        ) {
          this.saving = true
          await this.finishReconciliation(this.reconciliationId)
          this.showSuccess({ message: this.$t('Conciliação finalizada com sucesso.') })
          this.$router.push({ name: 'reconciliation-getnet-list' })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    async onUnmatch(item) {
      try {
        if (
          await this.confirm({
            text: this.$t(
              'Confirma a desassociação deste lançamento? Você poderá associá-lo novamente a outro lançamento.'
            ),
          })
        ) {
          this.saving = true
          await this.unmatchItem({ item, reconciliationId: this.reconciliationId })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    onSkip(item) {
      this.$refs.skipModal.showModal(item)
    },

    onMatch(item) {
      this.$refs.matchModal.showModal(item)
    },

    async onReset(item) {
      try {
        if (
          await this.confirm({
            text: this.$t('Confirma o reset deste lançamento? Ele voltará ao status "Pendente"'),
          })
        ) {
          this.saving = true
          await this.resetItem({ item, reconciliationId: this.reconciliationId })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-20px);
}
/* .h-345 .card {
  min-height: 345px;
} */
</style>
