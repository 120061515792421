<template>
  <b-modal
    id="modal-associate-item"
    v-model="localShowModal"
    :title="titleModal"
    no-close-on-backdrop
    scrollable
    hide-footer
    size="xl"
  >
    <e-filters
      :searching="fetching"
      @search="getData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="filter-sale-id"
              v-model="filters.saleId"
              :placeholder="$t('Id da venda')"
              type="number"
              :label="$t('Id da venda')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="filter-nsu"
              v-model="filters.nsu"
              :placeholder="$t('NSU')"
              type="number"
              :label="$t('NSU')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="filter-valueFrom"
              v-model="filters.valueFrom"
              type="text-number"
              currency="R$"
              :precision="2"
              :label="$t('Valor de')"
              :placeholder="$t('Valor de')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="filter-value-to"
              v-model="filters.valueTo"
              type="text-number"
              currency="R$"
              :precision="2"
              :label="$t('Valor até')"
              :placeholder="$t('Valor até')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="filter-date"
              v-model="filters.period"
              type="date-range-picker"
              :label="$t('Período da venda')"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching || saving"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="receivables-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('SALE.NAME'), 'female')"
        :fields="fields"
        :items="receivables"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <e-grid-actions
            v-if="buttons.length"
            :show-update="false"
            :show-delete="false"
            :buttons="buttons(row.item)"
            @match="onMatch(row.item)"
          />
          <span
            v-else
            :title="$this('Este lançamento já foi utilizado em outra associação')"
          >-</span>
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="receivables-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </b-modal>
</template>

<script>
import moment from 'moment'
import { BModal, BRow, BCol, BTable, BPagination } from 'bootstrap-vue'
import { EFilters, EGridActions, EPageSizeSelector, EPaginationSummary } from '@/views/components'
import { mapActions, mapState } from 'vuex'
import { financialDomain, stringUtils } from '@/mixins'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    EFilters,
    EGridActions,
    EPageSizeSelector,
    EPaginationSummary,
    BCardActions,
    BTable,
    BPagination,
  },
  mixins: [stringUtils],
  props: {
    reconciliation: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fetching: false,
      saving: false,
      localShowModal: false,
      localData: {
        getnetData: {},
      },
    }
  },
  computed: {
    ...mapState('pages/financial/reconciliation/getnet/maintain', ['allReconciliationItems']),
    ...mapState('pages/financial/receivables', ['filters', 'paging', 'sorting', 'receivables']),
    titleModal() {
      return `${this.$t('Associar')} - NSU: ${this.nsuAcquirer} | ${this.$t(
        'Valor'
      )}: ${this.$options.filters.currency(this.transactionValue)} | ${this.$t('Data')}: ${
        this.transactionTime
      } | ${this.$t('Captura')}: ${this.captureForm}`
    },
    reconciliationId() {
      return this.reconciliation?.id
    },
    nsuAcquirer() {
      return this.localData?.getnetData?.nsuAcquirer
    },
    transactionTime() {
      return this.$options.filters.datetime(this.localData?.getnetData?.transactionDate, '-', true)
    },
    transactionValue() {
      return this.localData?.getnetData?.installmentValue / 100
    },
    captureForm() {
      return this.localData?.getnetData?.captureForm
    },
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '50px' },
        },
        {
          label: this.$t('Venda'),
          key: 'receivable.sale.id',
          thStyle: { width: '130px' },
          tdClass: 'text-right',
        },
        {
          label: this.$t('Nsu'),
          key: 'receivable.nsu',
          thStyle: { width: '130px' },
          tdClass: 'text-right',
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value),
          sortable: true,
        },
        {
          label: this.$t('Data'),
          key: 'receivable.createdDate',
          tdClass: 'text-center',
          thStyle: { width: '150px' },
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Descrição'),
          key: 'receivable.description',
        },
      ]
    },
    buttons() {
      return item => {
        const matchButton = {
          icon: 'link',
          variant: 'primary',
          title: this.$t('Associar'),
          event: 'match',
        }

        const buttons = []
        const currentAssociation = this.allReconciliationItems.find(
          reconciliationItem =>
            reconciliationItem.receivableInstallment?.receivable?.nsu === item.receivable.nsu
        )

        if (!currentAssociation) {
          buttons.push(matchButton)
        }

        return buttons
      }
    },
  },
  watch: {
    data(val) {
      this.localData = val
    },
  },
  methods: {
    ...mapActions('pages/financial/reconciliation/getnet/maintain', [
      'matchItem',
      'fetchAllReconciliationItems',
    ]),
    ...mapActions('pages/financial/receivables', [
      'fetchReceivables',
      'resetFiltersForConciliationMatch',
      'setCurrentPage',
      'setPageSize',
      'setPeriodType',
      'setFilters',
      'setSorting',
    ]),
    async getData() {
      this.$nextTick(async () => {
        this.fetching = true
        try {
          this.setPeriodType(financialDomain.data().receivablePeriodTypeEnum.CREATED_DATE)
          await this.fetchReceivables()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },
    async showModal(data) {
      this.localData = data
      this.setFilters({
        ...this.filters,
        period: {
          startDate: moment(data.getnetData.transactionDate).startOf('day').format(),
          endDate: moment(data.getnetData.transactionDate).endOf('day').format(),
        },
        storeId: this.reconciliation?.checkingAccount?.store?.id,
        isPaid: false,
        valueFrom: this.transactionValue,
        valueTo: this.transactionValue,
      })
      this.setSorting({
        sortBy: 'receivable.createdDate',
        sortDesc: true,
      })
      this.localShowModal = true
      this.fetching = true
      // await this.fetchAllReconciliationItems(this.reconciliationId)
      this.getData()
    },
    hideModal() {
      this.localShowModal = false
    },
    async onMatch(item) {
      let confirmText = 'Confirma a associação deste lançamento?'
      let confirmTitle = this.$t('Confirma')
      const getnetNsu = parseInt(this.getOnlyNumbers(this.localData.getnetData.nsuAcquirer), 10)
      const erpNsu = parseInt(this.getOnlyNumbers(item.receivable.nsu), 10)
      if (getnetNsu !== erpNsu) {
        confirmText = `ATENÇÃO: O NSU da Getnet (${this.localData.getnetData.nsuAcquirer}) é diferente do NSU do recebível escolhido (${item.receivable.nsu}). Tem certeza que deseja realizar a associação?`
        confirmTitle = 'Cuidado!'
      }
      if (await this.confirm({ text: confirmText, title: confirmTitle })) {
        try {
          this.saving = true
          await this.matchItem({
            reconciliationId: this.reconciliationId,
            itemId: this.localData.id,
            receivableInstallmentId: item.id,
          })
          this.resetFiltersForConciliationMatch()
          this.hideModal()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      }
    },
    resetFiltersLocal() {
      this.resetFiltersForConciliationMatch()
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
  },
}
</script>
