<template>
  <div>
    <b-card-actions
      :show-loading="saving || fetching"
      action-collapse
      action-refresh
      :title="$t('Lançamentos da Conciliação')"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <e-filters
        :search-text="$t('Filtrar')"
        :searching="fetching"
        :title="$t('Filtrar Lançamentos')"
        class="mb-2 card-bordered"
        @search="getData"
        @reset="resetFiltersLocal"
      >
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="filters-nsu-getnet"
              v-model="filters.nsuGetnet"
              type="number"
              :label="$t('Nsu Getnet')"
              :placeholder="$t('Nsu')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="filters-nsu-erp"
              v-model="filters.nsuErp"
              type="number"
              :label="$t('Nsu ERP')"
              :placeholder="$t('Nsu')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="filters-status"
              v-model="filters.status"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              :options="statusReconciliationItemOptions"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="filters-input-method"
              v-model="filters.inputMethod"
              type="vue-select"
              :label="$t('Forma de associação')"
              :placeholder="$t('Todas')"
              :options="inputMethodReconciliationOptions"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="filters-value"
              v-model="filters.value"
              type="text-number"
              currency="R$"
              :precision="2"
              :label="$t('Valor Getnet')"
              :placeholder="$t('Valor')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="filters-acquirer_product_id"
              v-model="filters.acquirerProductId"
              type="vue-select"
              :options="getnetAcquirerProductsOptions"
              :label="$t('Forma de pagamento Getnet')"
              :placeholder="$t('Selecione')"
            />
          </b-col>
          <b-col md="2">
            <e-payment-method
              id="filters-payment_method_id"
              v-model="filters.paymentMethodIdErp"
              :label="$t('Forma de pagamento ERP')"
            />
          </b-col>
        </b-row>
      </e-filters>

      <b-table
        ref="reconciliation-items-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('RECONCILIATION_ITEM.NAME'), 'male')"
        :fields="fields"
        :items="reconciliationItems"
        :tbody-tr-class="rowClass"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #thead-top>
          <b-tr>
            <b-th />
            <b-th
              variant="primary"
              colspan="6"
            >
              {{ $t('Transação Getnet') }}
            </b-th>
            <b-th
              variant="primary"
              colspan="5"
            >
              {{ $t('Transação ERP') }}
            </b-th>
            <b-th colspan="2" />
          </b-tr>
        </template>
        <template #cell(transactionDate)="row">
          <span :title="$options.filters.datetime(row.item.getnetData.transactionDate)">{{
            row.item.getnetData.transactionDate | time('-', true)
          }}</span>
        </template>
        <template #cell(receivableDate)="row">
          <span
            v-if="row.item.receivableInstallment"
            :title="
              $options.filters.datetime(row.item.receivableInstallment.receivable.createdDate)
            "
          >{{ row.item.receivableInstallment.receivable.createdDate | time('-', true) }}</span>
        </template>
        <template #cell(saleId)="row">
          <e-router-link
            v-if="row.item.receivableInstallment"
            :title="$t('Visualizar venda')"
            :to="{
              name: 'order-read-only',
              params: { id: row.item.receivableInstallment.receivable.saleId },
            }"
            :value="row.item.receivableInstallment.receivable.saleId"
          />
        </template>

        <template #cell(receivablePaymentMethodId)="row">
          <FormulateInput
            v-if="row.item.receivableInstallment"
            v-model="row.item.receivableInstallment.receivable.paymentMethodId"
            label-container-class="d-none"
            :options="paymentMethodsOptions"
            type="label"
          />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :show-update="false"
            :show-delete="false"
            :buttons="buttons(row.item)"
            @match="onMatch(row.item)"
            @unmatch="onUnmatch(row.item)"
            @skip="onSkip(row.item)"
            @reset="onReset(row.item)"
          />
        </template>
        <template #custom-foot>
          <tr>
            <th colspan="2" />
            <th class="text-right">
              {{ getnetTotalValue | currency }}
            </th>
            <th class="text-right">
              {{ getnetTotalCommission | currency }}
            </th>
            <th colspan="5" />
            <th class="text-right">
              {{ erpTotalValue | currency }}
            </th>
            <th colspan="4" />
          </tr>
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="reconciliation-items-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
  </div>
</template>

<script>
import { BRow, BCol, BPagination, BTable, BTr, BTh } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
// import EButton from '@/views/components/EButton.vue'
import { reconciliationDomains } from '@/mixins'
import {
  EPageSizeSelector,
  EPaginationSummary,
  // EStoreCombo,
  EFilters,
  EGridActions,
  // ECrudActions,
} from '@/views/components'
import ERouterLink from '@/views/components/ERouterLink.vue'
import EPaymentMethod from '@/views/components/inputs/EPaymentMethod.vue'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BTable,
    BTr,
    BTh,
    BCardActions,
    EPageSizeSelector,
    EPaginationSummary,
    EFilters,
    EGridActions,
    ERouterLink,
    EPaymentMethod,
  },

  mixins: [reconciliationDomains],

  props: {
    saving: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('pages/financial/reconciliation/getnet/maintain', [
      'reconciliationItems',
      'paging',
      'sorting',
      'filters',
      'summaryFields',
    ]),

    ...mapGetters('app', ['paymentMethodsOptions']),
    ...mapGetters('pages/financial/reconciliation/getnet', ['getnetAcquirerProductsOptions']),

    getnetTotalValue() {
      return this.summaryFields?.getnetTotalValue?.value
    },
    getnetTotalCommission() {
      return this.summaryFields?.getnetTotalCommission?.value
    },
    erpTotalValue() {
      return this.summaryFields?.erpTotalValue?.value
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Nsu'),
          key: 'getnetData.nsuAcquirer',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Valor'),
          key: 'getnetData.installmentValue',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value / 100),
          sortable: true,
        },
        {
          label: this.$t('Comissão'),
          key: 'getnetData.commissionValue',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.currency(value / 100),
          sortable: true,
        },
        {
          label: this.$t('Captura'),
          key: 'getnetData.captureForm',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Forma'),
          key: 'getnetReconciliationSummaryItem.acquirerProduct.name',
          tdClass: 'text-center',
          thStyle: { minWidth: '130px', maxWidth: '150px' },
          sortable: true,
        },
        {
          label: this.$t('Hora'),
          key: 'transactionDate',
          sortKey: 'getnetData.transactionDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Nsu'),
          key: 'receivableInstallment.receivable.nsu',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          formatter: (value, index, item) => item.receivableInstallment?.receivable?.nsu,
          sortable: true,
        },
        {
          label: this.$t('Venda'),
          key: 'saleId',
          sortKey: 'receivableInstallment.receivable.saleId',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          // formatter: (value, index, item) => item.receivableInstallment?.receivable?.id,
          sortable: true,
        },
        {
          label: this.$t('Valor'),
          key: 'receivableInstallment.value',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: (value, index, item) =>
            item.receivableInstallment?.value
              ? this.$options.filters.currency(item.receivableInstallment?.value)
              : '',
          sortable: true,
        },
        {
          label: this.$t('Forma'),
          key: 'receivablePaymentMethodId',
          sortKey: 'receivableInstallment.receivable.paymentMethodId',
          tdClass: 'text-center',
          thStyle: { minWidth: '130px', maxWidth: '150px' },
          sortable: true,
        },
        {
          label: this.$t('Hora'),
          key: 'receivableDate',
          sortKey: 'receivableInstallment.createdDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'status',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.$t(value || '-'),
          sortable: true,
        },
        {
          label: this.$t('Comentário'),
          key: 'comments',
          formatter: value => value || '-',
          sortable: true,
        },
      ]
    },
    buttons() {
      return item => {
        const matchButton = {
          icon: 'link',
          variant: 'primary',
          title: this.$t('Associar com recebível'),
          event: 'match',
        }
        const unmatchButton = {
          icon: 'x-circle',
          variant: 'danger',
          title: this.$t('Desfazer associação'),
          event: 'unmatch',
        }
        const skipButton = {
          icon: 'skip-forward',
          variant: 'warning',
          title: this.$t('Ignorar lançamento'),
          event: 'skip',
        }
        const resetButton = {
          icon: 'x-circle',
          variant: 'danger',
          title: this.$t('Reiniciar status'),
          event: 'reset',
        }
        const buttons = []

        switch (item.status) {
          case 'Associated':
            buttons.push(unmatchButton)
            break
          case 'Pending':
            buttons.push(...[matchButton, skipButton])
            break
          case 'Skipped':
            buttons.push(...[matchButton, resetButton])
            break
          default:
            break
        }

        return buttons
      }
    },
  },

  mounted() {
    this.fetchGetnetAcquirerProducts()
  },

  methods: {
    ...mapActions('pages/financial/reconciliation/getnet/maintain', [
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),
    ...mapActions('pages/financial/reconciliation/getnet', ['fetchGetnetAcquirerProducts']),

    getData() {
      this.$emit('get-data')
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },

    onSkip(item) {
      this.$emit('item-skip', item)
    },

    onMatch(item) {
      this.$emit('item-match', item)
    },
    onUnmatch(item) {
      this.$emit('item-unmatch', item)
    },

    async onReset(item) {
      this.$emit('item-reset', item)
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return ''
      switch (item.status) {
        case this.statusReconciliationItemEnum.Completed:
        case this.statusReconciliationItemEnum.Associated:
          return 'table-success'
        case this.statusReconciliationItemEnum.Skipped:
          return 'table-warning'
        default:
          return ''
      }
    },
  },
}
</script>

<style></style>
