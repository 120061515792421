<template>
  <b-modal
    id="modal-skip-item"
    v-model="localShowModal"
    :title="titleModal"
    no-close-on-backdrop
    scrollable
    centered
    size="md"
  >
    <FormulateForm
      ref="form"
      @submit="onConfirm"
    >
      <b-row>
        <b-col>
          <FormulateInput
            id="skip-item-input-nsu"
            :value="transactionTime"
            :label="$t('Horário')"
            type="label"
          />
        </b-col>
        <b-col>
          <FormulateInput
            id="skip-item-input-value"
            :value="transactionValue / 100"
            :label="$t('Valor')"
            type="label"
            filter="currency"
          />
        </b-col>
        <b-col md="12">
          <FormulateInput
            id="skip-item-comments"
            v-model="localData.comments"
            :label="$t('Observação')"
            type="textarea"
            rows="3"
          />
        </b-col>
      </b-row>
    </FormulateForm>

    <template #modal-footer>
      <b-row class="w-100 d-flex justify-content-between align-items-center">
        <b-col class="d-flex justify-content-end">
          <e-button
            id="modal-skip-item-items-btn_cancel"
            class="mr-1"
            variant="outline-primary"
            :text="$t('Cancelar')"
            @click="hideModal"
          />

          <e-button
            id="modal-skip-item-items-btn_confirm"
            variant="primary"
            :text="$t('Confirmar')"
            :busy="saving"
            @click="onConfirm"
          />
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { mapActions } from 'vuex'

export default {
  components: { BModal, BRow, BCol, EButton },
  mixins: [],
  props: {
    reconciliationId: {
      type: Number,
      require: true,
      default: 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      saving: false,
      localShowModal: false,
      localData: {
        getnetData: {},
      },
    }
  },
  computed: {
    titleModal() {
      return `${this.$t('Ignorar transação')} / NSU: ${this.nsuAcquirer}`
    },
    nsuAcquirer() {
      return this.localData?.getnetData?.nsuAcquirer
    },
    transactionTime() {
      return this.$options.filters.time(this.localData?.getnetData?.transactionDate, '-', true)
    },
    transactionValue() {
      return this.localData?.getnetData?.installmentValue
    },
  },
  watch: {
    data(val) {
      this.localData = val
    },
  },
  methods: {
    ...mapActions('pages/financial/reconciliation/getnet/maintain', ['skipItem']),
    showModal(data) {
      this.localShowModal = true
      this.localData = data
    },
    hideModal() {
      this.localShowModal = false
    },
    async onConfirm() {
      if (this.$refs.form.isValid) {
        try {
          this.saving = true
          await this.skipItem({
            reconciliationId: this.reconciliationId,
            itemId: this.localData.id,
            comments: this.localData.comments,
          })
          this.hideModal()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },
  },
}
</script>
