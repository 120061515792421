var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-associate-item",
        title: _vm.titleModal,
        "no-close-on-backdrop": "",
        scrollable: "",
        "hide-footer": "",
        size: "xl",
      },
      model: {
        value: _vm.localShowModal,
        callback: function ($$v) {
          _vm.localShowModal = $$v
        },
        expression: "localShowModal",
      },
    },
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.getData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "filter-sale-id",
                          placeholder: _vm.$t("Id da venda"),
                          type: "number",
                          label: _vm.$t("Id da venda"),
                        },
                        model: {
                          value: _vm.filters.saleId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "saleId", $$v)
                          },
                          expression: "filters.saleId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "filter-nsu",
                          placeholder: _vm.$t("NSU"),
                          type: "number",
                          label: _vm.$t("NSU"),
                        },
                        model: {
                          value: _vm.filters.nsu,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "nsu", $$v)
                          },
                          expression: "filters.nsu",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "filter-valueFrom",
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                          label: _vm.$t("Valor de"),
                          placeholder: _vm.$t("Valor de"),
                        },
                        model: {
                          value: _vm.filters.valueFrom,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "valueFrom", $$v)
                          },
                          expression: "filters.valueFrom",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "filter-value-to",
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                          label: _vm.$t("Valor até"),
                          placeholder: _vm.$t("Valor até"),
                        },
                        model: {
                          value: _vm.filters.valueTo,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "valueTo", $$v)
                          },
                          expression: "filters.valueTo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "filter-date",
                          type: "date-range-picker",
                          label: _vm.$t("Período da venda"),
                        },
                        model: {
                          value: _vm.filters.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: {
            "action-refresh": "",
            "show-loading": _vm.fetching || _vm.saving,
          },
          on: { refresh: _vm.getData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "receivables-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("SALE.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.receivables,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _vm.buttons.length
                      ? _c("e-grid-actions", {
                          attrs: {
                            "show-update": false,
                            "show-delete": false,
                            buttons: _vm.buttons(row.item),
                          },
                          on: {
                            match: function ($event) {
                              return _vm.onMatch(row.item)
                            },
                          },
                        })
                      : _c(
                          "span",
                          {
                            attrs: {
                              title: _vm.$this(
                                "Este lançamento já foi utilizado em outra associação"
                              ),
                            },
                          },
                          [_vm._v("-")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "receivables-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }